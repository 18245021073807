import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
import sizesTable from '@/components/sizes/index.vue';
import { Carousel, Slide } from 'vue-carousel';
//sections
import mainButton from '@/components/main-button/index.vue';
import mainSelect from '@/components/main-select/index.vue';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {isMobile} from "../../helpers/variables";

// import WZoom from "../../utils/wheel-zoom.min";
import { Fancybox } from "../../utils/fancybox.esm";

export default {
    name: "product",
    components: {
        mainButton,
        mainSelect,
        sizesTable,
        Carousel,
        Slide,
        CoolLightBox
    },
    data() {
        return {
            trick: false,
            selectSize: false,
            popup: false,
            selected: false,
            imgCollection: [],
            horizontalImg: [],
            isMobile: false,
            mobile: false,
            zoomPluginObj: null
        }
    },
    head: {
        title: function () {

            return this.dress ? {
                inner: this.dress.title,
                separator: '|',
                complement: 'KATY CORSO',
            } : {
                inner: 'KATY CORSO',
            }
        },
        meta: function () {
            let meta = []
            if (this.dress) {
                let {metaDescription, metaKeywords, metaTitle } = this.dress;

                if (metaDescription) meta.push({n: 'description', c: metaDescription, id: 'description'})
                if (metaKeywords) meta.push({n: 'keywords', c: metaKeywords, id: 'keywords'})
                if (metaTitle) meta.push({n: 'og:title', c: metaTitle, id: 'og:title'})
                if (metaDescription) meta.push({n: 'og:description', c: metaDescription, id: 'og:description'})
            }
            return meta
        }
    },
    watch: {
        '$route'(newVal) {
            if (newVal) {
                this.fetchDress(this.$route.params.slug).then(()=>{
                    this.trick = true
                    setTimeout(()=>{
                        this.trick = false
                    },1)
                })
                this.imgCollection = []
                const carousel = this.$refs['carousel'];
                carousel.goToPage(0);
            }
        },
        dress: {
            immediate: true,
            handler: function(val) {
                if (val) this.$emit('updateHead');
            },
        }
    },
    created() {
        this.fetchDress(this.$route.params.slug).then(() => {
            this.trick = true
            setTimeout(()=>{
                this.trick = false
            },1)
            this.imgCollection = []
            this.trackFavorite()
        })
    },
    mounted() {
        AOS.init({
            disable: function () {
                const maxWidth = 700;
                return window.innerWidth <= maxWidth;
            }
        });
        window.innerWidth <= 700 ? this.isMobile = true : this.isMobile = false
        window.addEventListener('resize', () => {
            window.innerWidth <= 600 ? this.mobile = true : this.mobile = false
        })

        window.innerWidth <= 600 ? this.mobile = true : this.mobile = false
        this._initFancybox()
        if(window.navigator.userAgent.includes('Instagram')){
            this.mobile = true
            this.isMobile = true
        }

    },
    computed: {

        ...mapGetters({
            dressLoading: 'catalog/productLoading',
            dress: 'catalog/dress',
            isAuthenticated: `auth/isAuthenticated`,
            favorite: `basket/favorites`,
            contacts: 'pages/contact'
        }),
        getImgCollection() {
            this.imgCollection = []
            this.dress.images.data.forEach(img => {

                this.imgCollection.push({'src': img.imageThumbnail, 'gallery': img.imageOriginal,'type_display': img.typeDisplay})

            })
            return this.imgCollection
        },
    },

    methods: {
        ...mapActions({
            fetchDress: 'catalog/GET_DRESS',
        }),
        ...mapMutations({
            addItemToBasket: 'basket/ADD_ITEM_TO_BASKET',
            addFavoriteProduct: `basket/ADD_ITEM_TO_FAVORITES`,
            removeFavoriteProduct: `basket/REMOVE_ITEM_FROM_FAVORITES`
        }),
        carouselNav($event, direction) {
            const carousel = this.$refs['carousel'];
            carousel.advancePage(direction);
        },
        addToCart() {
            this.addItemToBasket({
                selectSize: this.selectSize.eur,
                selectSizeId: this.selectSize.id,
                selectWeight: this.selectSize.weight,
                selectCount: 1,
                ...this.dress
            })
            this.$toasted.success(this.$t('addCart'))
            // this.cartItem.size = this.selectSize.eur
        },
        togglePopup() {
            this.popup = !this.popup
        },
        changeFavorite(product){
            if(!product.selected){
                product.selected = true
                this.addFavoriteProduct(product)
            }
            else {
                product.selected = false
                this.removeFavoriteProduct(product)
            }
        },
        trackFavorite() {
                if (!this.dress) return {}
                if (!this.favorite) return []
                this.favorite.forEach((e) => {
                    if (e.slug === this.dress.slug) {
                        this.dress.selected = true
                    }
                })
        },
        _initFancybox() {
            Fancybox.bind('[data-fancybox="gallery"]', {
                Image: {
                    click: null,
                    Panzoom: {
                        ratio: 2,
                        zoomFriction: 0.7,
                        maxScale: function () {
                            return 10;
                        },
                        fit: 'cover'
                    },
                },
                Thumbs : {
                    minSlideCount : 10
                },
                Toolbar: {
                    display: [
                        {
                            id: "counter",
                            position: "left",
                        },
                        "slideshow",
                        "fullscreen",
                        "close",
                    ],
                },
                on: {
                    initLayout: (fancybox) => {
                        console.log('test')

                        // buttonZoomIn
                        const buttonZoomIn = document.createElement('button');
                        buttonZoomIn.innerHTML = '+';
                        buttonZoomIn.addEventListener('click', (event) => {
                            event.preventDefault();
                            Fancybox.getInstance().getSlide().Panzoom.zoomIn()
                        });
                        buttonZoomIn.className = 'carousel__button fancybox__button--zoom-in';


                        // buttonZoomOut
                        const buttonZoomOut = document.createElement('button');
                        buttonZoomOut.innerHTML = '-';
                        buttonZoomOut.addEventListener('click', (event) => {
                            event.preventDefault();
                            Fancybox.getInstance().getSlide().Panzoom.zoomOut()
                        });
                        buttonZoomOut.className = 'carousel__button fancybox__button--zoom-out';


                        // Add elements to DOM after Toolbar plugin create elements
                        setTimeout(()=>{
                            let container = fancybox.plugins.Toolbar.$container.querySelector('.fancybox__toolbar__items.fancybox__toolbar__items--right')
                            container.prepend(buttonZoomOut);
                            container.prepend(buttonZoomIn);
                        },100)
                    },
                },
            });
        },

    },
}
